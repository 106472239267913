import { ModelWarehouseType } from '@/graphql/types.generated';
import {
  IApplicationFeatureFlagToggles,
  IFeatureFlagToggles,
  IWarehouseFeatureFlagToggles,
} from '@context/feature-flags/feature-flag-types';
import { InventoryLevel, InventorySection } from '@models/inventory';

const DEFAULT_APPLICATION_FEATURE_FLAG_TOGGLES: IApplicationFeatureFlagToggles = {
  companies: {
    allowCreate: false,
    allowEditDetails: false,
    allowEditContactInfo: false,
    allowEditDisplayPref: false,
  },
  dataTables: {
    totalCountRefreshMs: 60000,
    savedPaginationTTL: 300000,
  },
  guide: {
    enabled: false,
  },
  maintenanceMode: {
    enabled: false,
  },
  organization: {
    allowEditDetails: false,
    allowEditContactInfo: false,
    allowEditDisplayPref: false,
  },
  stockStatuses: {
    allowCreate: false,
    allowEdit: false,
    allowDelete: false,
  },
  warehouses: {
    allowCreate: false,
    allowEditDetails: false,
    allowEditContactInfo: false,
    allowEditDisplayPref: false,
  },
};

export const DEFAULT_WAREHOUSE_FEATURE_FLAG_TOGGLES: IWarehouseFeatureFlagToggles = {
  barcodes: {
    showNonCompliantTable: false,
  },
  businessPartners: {
    allowCreate: false,
    allowUpdate: false,
    allowDelete: false,
  },
  deliveries: {
    assignDoor: false,
    unassignDoor: false,
    showAsnOcInfo: false,
    showCategory: false,
    showERPDates: false,
    showERPCode: false,
    showGoodsIssueReceipt: false,
    showLoadUnload: false,
    showERPBlock: false,
    showAvailability: false,
  },
  doors: {
    allowCreate: false,
    allowEdit: false,
    allowDelete: false,
  },
  inboundDeliveries: {
    showPGR: false,
    manageInboundLPs: false,
  },
  inventory: {
    deliveryAssociated: false,
    sectionsEnabled: {
      [InventorySection.Counting]: false,
      [InventorySection.Inventory]: false,
    },
    levelsEnabled: {
      [InventoryLevel.Bin]: false,
      [InventoryLevel.Lot]: false,
      [InventoryLevel.LostAndFound]: false,
      [InventoryLevel.LP]: false,
      [InventoryLevel.Product]: false,
    },
    levels: {
      [InventoryLevel.Bin]: {
        showAdjustInventory: false,
      },
    },
    binLevelActions: {
      adjustInventory: false,
      updateStockStatus: false,
      createCount: false,
      createBinToBin: false,
      createIssueStock: false,
    },
    licensePlateActions: {
      add: false,
      block: false,
      create: false,
      merge: false,
      move: false,
      unpack: false,
    },
    lostAndFoundActions: {
      issueStock: false,
      editStock: false,
      binToBin: false,
    },
  },
  licensePlates: {
    details: {
      showNestedTable: false,
    },
  },
  navigation: {
    landingPage: 'operations',
    sidebarNavigation: ['wms', 'slotting'],
  },
  outboundDeliveries: {
    allowBlock: false,
    allowClose: false,
    allowEdit: false,
    allowShortShip: false,
    showPGI: false,
    showPromiseDate: false,
    loadCreate: {
      columnType: 'product',
    },
    pickTaskCreation: 'generic',
  },
  pagesEnabled: {
    map: false,
    settings: {
      agentConfig: false,
      apiLogs: false,
      contractorValidationLogs: false,
      developerTools: false,
      erpSystemAuth: false,
      hardware: false,
      inboundFileProcessing: false,
      licensePlates: false,
      numberRanges: false,
      organization: false,
      processes: false,
      queueLogs: false,
      serialNumbers: false,
      stockStatus: false,
      sapSettings: {
        company: false,
        costCenters: false,
        documentTypes: false,
        movementTypes: false,
        syncSettings: false,
        warehouseMapping: false,
      },
    },
    support: false,
  },
  settings: {
    defaultFilters: [],
  },
  tasks: {
    actions: {
      editUoM: false,
      cancelTasks: false,
    },
  },
};

const DEFAULT_FEATURE_FLAG_TOGGLES: IFeatureFlagToggles = {
  ...DEFAULT_APPLICATION_FEATURE_FLAG_TOGGLES,
  warehouseTypes: {
    [ModelWarehouseType.Default]: DEFAULT_WAREHOUSE_FEATURE_FLAG_TOGGLES,
    [ModelWarehouseType.Aoe]: DEFAULT_WAREHOUSE_FEATURE_FLAG_TOGGLES,
    [ModelWarehouseType.Cs]: DEFAULT_WAREHOUSE_FEATURE_FLAG_TOGGLES,
    [ModelWarehouseType.Pto]: DEFAULT_WAREHOUSE_FEATURE_FLAG_TOGGLES,
    [ModelWarehouseType.Slotting]: DEFAULT_WAREHOUSE_FEATURE_FLAG_TOGGLES,
  },
};

export default DEFAULT_FEATURE_FLAG_TOGGLES;
